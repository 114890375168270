$(() => {
  $('#local_link > button').on('click', () => {
    $('#saml_login').hide();
    $('#local_login').show();
  });

  $('#saml_link > button').on('click', () => {
    $('#saml_login').show();
    $('#local_login').hide();
  });

  $('.login-form').each((index, formElement) => {
    $(formElement).on('submit', (event) => {
      if (formElement.checkValidity() === true) {
        $('.login-form input').prop('readonly', true);
      }
    });
  });
});
